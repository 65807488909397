import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import DesktopMenu from './desktop-menu'
import MobileMenu from './mobile-menu'
import { sizes, padding, breakpoints } from '../styles/variables'

const query = graphql`
  query navigationQuery {
    sanityNavigation {
      links: _rawLinks(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default function Header() {
  const queryData = useStaticQuery(query)
  const { links } = queryData?.sanityNavigation
  return (
    <Head className='theme'>
      <div className='header-content'>
        <div className='name'>
          <Link to='/'>Andrew Oppenheimer</Link>
        </div>
        <DesktopMenu links={links}/>
        <MobileMenu links={links}/>
      </div>
    </Head>
  )
}

const Head = styled.header(props => {
  return css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: ${sizes.headerHeightMobile};
    z-index: 2;
    .header-content {
      display: flex;
      width: 100%;
      max-width: ${sizes.constrainWidth};
      height: ${sizes.headerHeight};
      margin: 0 auto;
      padding: 0.5rem;
      .name {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem;
        @media (${breakpoints.phoneLarge}) {
          padding: 0 2.75rem;
        }
        @media (${breakpoints.tablet}) {
          padding: 0;
        }
      }
      .name > a {
        font-size: 1rem;
        text-decoration: none;
        @media (${breakpoints.phoneLarge}) {
          font-size: 1.25rem;
        }
        @media (${breakpoints.tablet}) {
          font-size: 1.5rem;
        }
      }
      @media (${breakpoints.tablet}) {
        padding: 1.5rem;
      }
    }

    @media (${breakpoints.laptop}) {
      height: ${sizes.headerHeight};

      .header-content {
        height: ${sizes.headerHeight};
        padding: ${padding.gutters};
        align-items: center;
      }
    }
  `
})
