import React, { useState } from 'react'
import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import globalStyles from '../styles'
import { sizes, breakpoints } from '../styles/variables'
import Header from './header'
import Footer from './footer'
import Theme from './theme'
import { isSSR } from '../../../utils'

export default function Layout({ children }) {
  const [theme, setTheme] = useState(!isSSR(typeof window) ? sessionStorage.getItem('aoppenhTheme') ?? 'default' : 'default')

  return (
    <>
      <Global styles={globalStyles(theme)} />
      <Header/>
      <Theme setTheme={setTheme}/>
      <Div>
        <main>{children}</main>
        <Footer/>
      </Div>
    </>
  )
}

const Div = styled.div(props => {
  return css`
    min-height: calc(100vh - ${sizes.headerHeightMobile});
    margin-top: ${sizes.headerHeightMobile};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    main {
      flex: 1;
      width: 100%;
      margin: 0 auto;
    }

    @media (${breakpoints.laptop}) {
      min-height: calc(100vh - ${sizes.headerHeight});
      margin-top: ${sizes.headerHeight};
    }
  `
})
