import React from 'react'
import { Link } from 'gatsby'

export default function GatsbyLink({link, text, className}) {
    const internal = /^\/(?!\/)/.test(link)

    return (
        internal ?
        <Link className={className} to={link}>
            {text}
        </Link>
        :
        <a className={className} href={link} target='_blank' rel='noreferrer'>
            {text}
        </a>
    )
}