import React from 'react'
import { css } from '@emotion/core'

export default function Footer() {
  return (
    <footer className='theme' css={styles}>
      <span>
        ©&nbsp;{new Date().getFullYear()}
        &nbsp;Andrew&nbsp;Oppenheimer&emsp;&emsp;&emsp;&emsp;
      </span>
    </footer>
  )
}

const styles = css`
  width: 100%;
  height: 6vh;
  min-height: 30px;
  max-height: 45px;
  display: flex;
  align-items: center;
  span {
    padding-left: 20px;
    font-size: 15px;
  }
`
