import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import GatsbyLink from './gatsby-link'
import { breakpoints } from '../styles/variables'

export default function DesktopMenu({links}) {
    return (
        <Div>
            <ul>
                {links.map((link, index) => {
                    return (
                        <li key={`menu-${index}`}>
                            <GatsbyLink className='theme-link-menu' link={link.url.current} text={link.text}/>
                        </li>
                    )
                })}
            </ul>
        </Div>
    )
}

const Div = styled.div(props => {
    return css`
        display: none;
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            li {
                padding: 5px;
            }
            a {
                text-decoration: none;
                transition: 0.4s;
                font-weight: bold;
                font-size: 1.75vw;
                @media(${breakpoints.laptop}) {
                    font-size: 0.9rem;
                }
            }
        }
        @media(${breakpoints.laptop}) {
            display: flex;
        }
    `
})