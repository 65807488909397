import { css } from '@emotion/core'
import { colors, breakpoints, fonts, sizes, padding } from './variables'

export default function globalStyles(theme) {
  const getTheme = (theme) => {
    switch(theme) {
      case 'default':
        return {
          icon: colors.background,
          backdrop: colors.body,
          body: colors.body,
          background: colors.background,
          text: colors.primary,
          link: colors.link,
          hover: colors.hover
        }
      case 'gold':
        return {
          icon: 'goldenrod',
          backdrop: 'grey',
          body: 'grey',
          background: 'goldenrod',
          text: 'ghostwhite',
          link: 'gold',
          hover: 'lightgrey'
        }
      case 'washed':
        return {
          icon: 'grey',
          backdrop: 'lightgrey',
          body: 'lightgrey',
          background: 'grey',
          text: 'black',
          link: 'blue',
          hover: 'blue'
        }
      case 'light':
        return {
          icon: 'lightgrey',
          backdrop: 'grey',
          body: 'lightgrey',
          background: 'white',
          text: 'grey',
          link: 'blue',
          hover: 'red'
        }
      case 'dark':
        return {
          icon: '#1E1E1f',
          backdrop: 'black',
          body: 'black',
          background: '#1E1E1f',
          text: 'white',
          link: 'lightblue',
          hover: 'blue'
        }
      default:
        return {
          icon: colors.background,
          backdrop: colors.body,
          body: colors.body,
          background: colors.background,
          text: colors.primary,
          link: colors.link,
          hover: colors.hover
        }
    }
  }

  const Theme = getTheme(theme)
  const Default = getTheme('default')
  const Gold = getTheme('gold')
  const Washed = getTheme('washed')
  const Light = getTheme('light')
  const Dark = getTheme('dark')


  return css`
    html {
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      font: 112.5%/1.45em ${fonts.primary};
      box-sizing: border-box;
      overflow-y: scroll;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    body {
      margin: 0;
      background: ${Theme.body};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: hsla(0, 0%, 0%, 0.8);
      font-family: ${fonts.primary};
      font-weight: normal;
      word-wrap: break-word;
      font-kerning: normal;
      -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
      -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
      -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
      font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    }

    a {
      color: ${Theme.link};
    }

    a:hover {
      color: ${Theme.hover};
    }
    
    .name > a {
      color: ${Theme.text};
    }
    
    .name > a:hover {
      color: ${Theme.text};
    }

    .theme {
      background: ${Theme.background};
    }

    .theme-link {
      color: ${Theme.link};
    }

    .theme-link:hover {
      color: ${Theme.hover};
    }

    .theme-icon {
      border-top: 2px solid ${Theme.background};
      border-left: 2px solid ${Theme.background};
      background: ${Theme.backdrop};
      color: ${Theme.icon};
    }

    .theme-panel {
      background: white;
    }
    
    .title-bar {
      border-top: 2px solid ${Theme.body};
    }

    .mobile .title-bar {
      @media(${breakpoints.tablet}) {
        border-right: 2px solid ${Theme.body};
      }
    }

    h1, h2, h3, h4, h5, h6, p {
      max-width: ${sizes.constrainWidth};
      padding: ${padding.headingsMobile};
      margin: auto;
      @media(${breakpoints.phoneLarge}) {
          padding:${padding.headings};
      }
    }

    h1, h2, h3, h4, h5, h6, p, a, div, input[type='submit'] {
      color: ${Theme.text};
      user-select: none;
    }
    
    span.default {
      background: ${Default.backdrop};
      color: ${Default.icon};
      border-radius: 10px 0 0 0;
    }

    span.gold {
      background: ${Gold.backdrop};
      color: ${Gold.icon};
    }

    span.washed {
      background: ${Washed.backdrop};
      color: ${Washed.icon};
    }

    span.light {
      background: ${Light.backdrop};
      color: ${Light.icon};
    }
    span.dark {
      background: ${Dark.backdrop};
      color: ${Dark.icon};
      border-radius: 0 0 0 10px;
    }

    @media only screen and (max-width: 480px) {
      html {
        font-size: 100%;
      }
    }
  `
}
