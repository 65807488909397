import React, { useState } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { breakpoints } from '../styles/variables'

export default function PanelType(props) {
    const { title, constantFullscreen, closeRotate, iconRotate, iconFont, closeFont, iconPadding, iconAbsolute, children } = props

    const [open, setOpen] = useState(false)

    return (
        <Div
            closeRotate={closeRotate}
            iconRotate={iconRotate}
            constantFullscreen={constantFullscreen}
            iconFont={iconFont}
            closeFont={closeFont}
            iconPadding={iconPadding}
            iconAbsolute={iconAbsolute}
        >
            <div className='title-bar theme'>
                {title && <div>{title}</div>}
                <props.openIcon className='icon' onClick={() => {setOpen(true)}}/>
            </div>
            <div
                className={`backdrop${open ? ' open' : ''}`}
                onClick={() => setOpen(false)}
            />
            <div className={`panel-content${open ? ' open' : ''} theme`}>
                <props.closedIcon className='close' onClick={() => setOpen(false)} />
                {children}
            </div>
        </Div>
    )
}

const Div = styled.div(props => {
    return css`
        .title-bar {
            display: flex;
            justify-content: space-between;
            ${props?.iconPadding && 'padding: calc(1rem - 10px)'};
            > div {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-size: 1.5rem;
            }
            .icon {
                font-size: ${props?.iconFont ? `${props.iconFont}` : '2'}rem;
                cursor: pointer;
                ${props?.iconAbsolute &&
                `position: absolute;
                right: 10px;
                top: 20px;`}
                ${props?.iconRotate && `transform: rotate(${props.iconRotate}deg);`}
            }
        }
        .panel-content {
            overflow-y: auto;
            padding: 2rem;
            height: 100%;
            opacity: 0.95;
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            ${!props?.constantFullscreen &&
            `@media(${breakpoints.tabletSmall}) {
                width: 65%;
            }
            @media(${breakpoints.tablet}) {
                width: 45%;
            }`}
            z-index: 200;
            box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
            transform: translateX(100%);
            transition: transform 0.3s ease-out;
            h1, h2, h3, h4, h5, h6, p, img {
                padding: 10px;
            }
        }
        .panel-content.open {
            transform: translateX(0);
        }
        .backdrop { 
            background: transparent;
            opacity: 0.35;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
        }
        .backdrop.open {
            width: 100vw;
            height: 100vh;
        }
        @media(${breakpoints.laptop}) {
            display: none;
        }
        .close {
            cursor: pointer;
            font-size: ${props?.closeFont ? `${props.closeFont}` : '2'}rem;
            ${props?.closeRotate && `transform: rotate(${props.closeRotate}deg);`}
        }
    `
})