import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { CgMenu, CgClose } from 'react-icons/cg'
import Panel from './panel'
import GatsbyLink from './gatsby-link'

export default function MobileMenu({links}) {
  return (
    <Div>
      <Panel
        iconFont={2}
        iconAbsolute={true}
        openIcon={CgMenu}
        closedIcon={CgClose}
      >
        <ul>
          {links.map((link, index) => {
            return (
              <li key={`menu-${index}`}>
                <GatsbyLink className='theme-link-menu' link={link.url.current} text={link.text}/>
              </li>
            )
          })}
        </ul>
      </Panel>
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    ul {
      list-style: none;
    }
    li {
        padding: 10px;
    }
    a {
        text-decoration: none;
        transition: 0.4s;
        font-weight: bold;
        font-size: 1.5rem;
    }
  `
})
