import React, { useState } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { FaCircle } from 'react-icons/fa'
import { isSSR } from '../../../utils'

export default function Theme({ setTheme }) {
    const [open, setOpen] = useState(false)

    const setThemeStorage = (theme) => {
        setTheme(theme)
        if (!isSSR(typeof window)) {
            sessionStorage.setItem('aoppenhTheme', theme)
        }
    }

    return (
        <Div>
            <div className='icon theme-icon'>
                <FaCircle onClick={() => setOpen(true)}/>
            </div>
            <div className={`menu${open ? ' open' : ''} theme-panel`}>
                <span className='default'>
                    <FaCircle
                        onClick={() => {
                            setThemeStorage('default')
                            setOpen(false)
                        }
                    }/> 
                </span>
                <span className='gold'>
                    <FaCircle
                        onClick={() => {
                            setThemeStorage('gold')
                            setOpen(false)
                        }
                    }/>  
                </span>
                <span className='washed'>
                    <FaCircle
                        onClick={() => {
                            setThemeStorage('washed')
                            setOpen(false)
                        }
                    }/> 
                </span>
                <span className='light'>
                    <FaCircle
                        onClick={() => {
                            setThemeStorage('light')
                            setOpen(false)
                        }
                    }/> 
                </span>
                <span className='dark'>
                    <FaCircle
                        onClick={() => {
                            setThemeStorage('dark')
                            setOpen(false)
                        }
                    }/>  
                </span>
            </div>
        </Div>
    )
}

const Div = styled.div(props => {
    return css`
        .icon, .menu {
            position: fixed;
            right: 0;
            bottom: 0;
        }
        .icon {
            cursor: pointer;
            padding: 5px 5px 0 5px;
            font-size: 30px;
            border-top-left-radius: 10px;
            z-index: 2;
        }
        .menu {
            display: flex;
            flex-direction: column;
            border-radius: 10px 0 0 0;
            padding: 3.5px 0 3.5px 3.5px;
            z-index: 3;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                cursor: pointer;
                padding: 5px;
            }
            box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
            transform: translatey(100%);
            transition: transform 0.3s ease-out;
        }
        .menu.open {
            transform: translateY(0);
        }
    `
})